import {
  CompensationFilters,
  CustomRoleTaxonomySelection,
} from '@revelio/data-access';
import {
  convertRoleToCustomRoleFilter,
  Filter,
  FilterItem,
  FilterName,
  FilterParameterKeys,
  FilterTypes,
  formatAndBreakoutFilters,
  GqlFilterKeyMapper,
  LocalSelectionCategories,
  OtherFilterNames,
  SelectionCategories,
} from '@revelio/filtering';
import { mapKeys } from 'lodash';

export const transformFiltersToPayQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
}): CompensationFilters => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

export const transformFiltersToPostingsQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
}) => {
  const filtersWithProvider: Filter[] = [
    ...filters,
    {
      id: LocalSelectionCategories.PROVIDER,
      value: [
        {
          id: 4,
          label: 'Indeed',
          shortName: 'Indeed',
          parentId: -1,
        },
        {
          id: 2,
          label: 'Website Postings',
          data: { shortName: 'Website Postings' },
        },
        {
          id: 3,
          label: 'LinkedIn',
          shortName: 'LinkedIn',
          parentId: -1,
        },
      ],
      isMulti: true,
      label: LocalSelectionCategories.PROVIDER,
      selectionListId: LocalSelectionCategories.PROVIDER,
      type: FilterTypes.SELECT,
    },
  ] as Filter[];

  const { formattedFilter } = formatAndBreakoutFilters(filtersWithProvider, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};
