import { PropsWithChildren } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';

export const MeterContainer = ({
  title,
  children,
}: PropsWithChildren & { title: string }) => {
  return (
    <Flex flexDirection="column" flexBasis="25%" height="full" paddingX="16px">
      <Text fontSize="xs" fontWeight="600" color="#2D426A">
        {title}
      </Text>
      <Center width="full">{children}</Center>
    </Flex>
  );
};
