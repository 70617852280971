import { Box, Flex, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { BarChartHorizontal } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { useContext, useMemo, useState } from 'react';
import {
  GeographyBarChartsData,
  GeographySummaryMetric,
  SingleOption,
} from '../../types';
import { getGeographyBarChartProps } from '../utils/get-geography-bar-chart-props';
import { getGeographyCompositionData } from '../utils/get-geography-composition-data';
import { StyledPlotSelect } from '../../../../shared/components/plots/styled-plot-select';
import { ChartsRenderedContext } from '../../company-summary/charts-rendered-context';

interface GeographySummaryTopSkillsProps {
  data: CompositionDataQuery | undefined;
}

export const GeographySummaryTopSkills = ({
  data,
}: GeographySummaryTopSkillsProps) => {
  const compositionData: GeographyBarChartsData = useMemo(
    () => getGeographyCompositionData(data),
    [data]
  );

  const [selectedChart, setSelectedChart] = useState<SingleOption>({
    value: 'skills',
    label: 'Skills',
  });

  const chartProps = useMemo(
    () =>
      getGeographyBarChartProps(
        compositionData,
        selectedChart,
        'geography-summary-top-skills'
      ),
    [compositionData, selectedChart]
  );

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <Card height="100%" variant="unstyled" borderRadius={'8px'}>
      <Box height="100%" p={4}>
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight={600}>
            Top {selectedChart?.label}
          </Text>
          <StyledPlotSelect
            value={selectedChart}
            onChange={(value) => setSelectedChart(value)}
            options={[
              { value: GeographySummaryMetric.Skills, label: 'Skills' },
              { value: GeographySummaryMetric.Roles, label: 'Roles' },
            ]}
          />
        </Flex>

        <Box height="85%">
          <BarChartHorizontal
            {...chartProps}
            setChartHasRendered={setChartHasRendered}
          />
        </Box>
      </Box>
    </Card>
  );
};
