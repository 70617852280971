import {
  CompositionDataQueryVariables,
  CustomRoleTaxonomySelection,
  Dimension1,
  Filters,
} from '@revelio/data-access';
import { PrimaryView } from '@revelio/core';
import { mapKeys } from 'lodash';
import {
  Filter,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  OtherFilterNames,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
  formatAndBreakoutFilters,
  getCompositionOvertimeRequiredSubFilterIds,
  mergeFiltersTogether,
  FilterItem,
  convertRoleToCustomRoleFilter,
} from '@revelio/filtering';

type TransformFiltersToVariables = {
  view: PrimaryView;
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
};

const getMappedFilters = (
  filters: TransformFiltersToVariables['filters'],
  isCustomRoleTaxonomyEnabled: TransformFiltersToVariables['isCustomRoleTaxonomyEnabled']
) => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mergedFilters = mergeFiltersTogether(
    isCustomRoleTaxonomyEnabled
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    [[SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY]]
  );

  const mappedFilters = mapKeys(
    mergedFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof mergedFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

export const getDim1FromView = (view: PrimaryView): Dimension1 => {
  switch (view) {
    case PrimaryView.COMPANY:
      return Dimension1.Company;
    case PrimaryView.GEOGRAPHY:
      return Dimension1.Geography;
    case PrimaryView.ROLE:
      return Dimension1.Role;
  }
};

/** ================================
 * Snapshot
 ================================ */
export const transformFiltersToSnapshotVariables = ({
  view,
  filters,
  selectionListsData,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters: Filters = getMappedFilters(
    filters,
    isCustomRoleTaxonomyEnabled
  );

  const categorySubFilters = getCompositionOvertimeRequiredSubFilterIds({
    primaryView: view,
    /** Don't send undefined values to sub filter conversion */
    selectedPageFilters: Object.entries(mappedFilters).reduce(
      (acc, [key, val]) => {
        if (val) return { ...acc, [key]: val };
        return acc;
      },
      {}
    ),
    selectionLists: selectionListsData,
    isCustomRoleTaxonomyEnabled,
  });

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: {
        skill: categorySubFilters?.skill,
      },
      start_date: mappedFilters.end_date,
    },
  };
};

/** ================================
 * Overtime
 ================================ */
export const transformFiltersToOvertimeVariables = ({
  view,
  filters,
  selectionListsData,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters = getMappedFilters(filters, isCustomRoleTaxonomyEnabled);

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: getCompositionOvertimeRequiredSubFilterIds({
        primaryView: view,
        /** Don't send undefined values to sub filter conversion */
        selectedPageFilters: Object.entries(mappedFilters).reduce(
          (acc, [key, val]) => {
            if (val) return { ...acc, [key]: val };
            return acc;
          },
          {}
        ),
        selectionLists: selectionListsData,
        isCustomRoleTaxonomyEnabled,
      }),
    },
  };
};
