import { PrimaryView } from '@revelio/core';
import {
  CustomRoleTaxonomySelection,
  Filters,
  PostingsDataQueryVariables,
} from '@revelio/data-access';
import {
  Filter,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  SelectionCategories,
  formatAndBreakoutFilters,
  mergeFiltersTogether,
  OtherFilterNames,
  convertRoleToCustomRoleFilter,
  FilterItem,
} from '@revelio/filtering';
import { mapKeys } from 'lodash';
import { getDim1FromView } from './get-dim1-from-view';

type TransformFiltersToPostingVariablesProps = {
  view: PrimaryView;
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
};
export const transformFiltersToPostingVariables = ({
  view,
  filters,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToPostingVariablesProps): PostingsDataQueryVariables => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mergedFilters = mergeFiltersTogether(
    isCustomRoleTaxonomyEnabled
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    [[SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY]]
  );

  const mappedFilters: Filters = mapKeys(
    mergedFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof mergedFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return {
    dim1: getDim1FromView(view),
    filters: mappedFilters,
  };
};
