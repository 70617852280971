import {
  setProps,
  distinctUntilArrayItemChanged,
  StoreValue,
  select,
  filterNil,
} from '@ngneat/elf';
import {
  selectAllEntities,
  setActiveIds,
  selectEntity,
  upsertEntitiesById,
  selectManyByPredicate,
  deleteEntities,
  selectActiveEntities,
  removeActiveIds,
  updateEntities,
  getActiveIds,
  upsertEntities,
  hasEntity,
  EntitiesRef,
  getEntity,
} from '@ngneat/elf-entities';
import {
  createRequestDataSource,
  trackRequestResult,
  updateRequestsCache,
} from '@ngneat/elf-requests';
import { persistState } from '@ngneat/elf-persist-state';
import localForage from 'localforage';
import { createEffectFn } from '@ngneat/effects';
import {
  shareReplay,
  switchMap,
  tap,
  map,
  catchError,
  startWith,
  filter,
  debounceTime,
  exhaustMap,
  first,
} from 'rxjs/operators';
import {
  isEmpty,
  isArray,
  isFinite,
  isString,
  isObjectLike,
  keys,
  uniq,
  isBoolean,
  inRange,
  mapKeys,
  omitBy,
  get,
  pickBy,
  unset,
  has,
  compact,
  isUndefined,
  isNumber,
} from 'lodash';
import { fromFetch } from 'rxjs/fetch';
import {
  combineLatest,
  isObservable,
  Observable,
  of,
  pipe,
  Subject,
  throwError,
} from 'rxjs';
import {
  API_ROOT,
  GO_API_ROOT,
  FILTER_STORAGE_KEY,
  PYTHON_COMPANY_SELECTION_ID,
  filterLabelLookup,
  ALL_ROLE_FILTERS,
} from './filters.constants';
import {
  EnumeratedFilters,
  FilterBase,
  FilterItem,
  FilterList,
  FilterNameIds,
  FilterTypes,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  SerializedFiltersForQuery,
  ProvidedFilterOrRawValueMap,
  ValidValueTypes,
  SelectionListResponse,
  OtherFilterNames,
  IQueryEndpoint,
  RequestMethod,
  IInternalDataFetch,
  LocalSelectionCategories,
  SelectionListIdNames,
  FilterStoreRootProps,
  RangeFilter,
  DateRangeFormattedValues,
  FilterName,
  OPValues,
  Item,
  Filter,
  OperatorOptionType,
  FilterValue,
} from './filters.model';
import {
  addLoadingStatus,
  MAX_FILTER_STORE_CACHE_AGE,
  maybeCatchError,
  pickKeys,
  PrimaryFilters,
  Views,
  write,
} from '@revelio/core';
import { d3DataCache } from '../cache';
import {
  AuthEventIds,
  authStore,
  emitAuthEvent,
  getAuthStoreUser,
  getUserMetadataValue,
  handleAuthResponse,
} from '@revelio/auth';
import { useEffect, useState } from 'react';
import { getActiveSetId } from './filters.storedset';
import {
  storedFilterSetEntitiesRef,
  selectionListEntitiesRef,
  filterStore,
} from './filters.core';
import {
  convertRoleToCustomRoleFilter,
  formatAndBreakoutFilters,
} from './filters.serialize';
import { PlotAdditionalQueryParams } from '../data-api/data-api.model';
import { GqlFilterKeyMapper } from './transformers.gql';
import { filtersWithCurrentOption } from '../filter-components/filter-menu/lookups.config';
import { SALARY_MAX } from '../filter-components/filter-range-slider/filter-range-slider-simplified';
import {
  CustomRoleTaxonomySelection,
  Maybe,
  MetadataKey,
  TalentDiscoverySalaryRange,
  TalentDiscoveryV1_5Filter,
} from '@revelio/data-access';
import { KibanaLogger } from '@revelio/iso-utility';
import { isNestedArray } from '../utils/is-nested-array';
import produce from 'immer';

function preStoreInit(mergedState: any, clearHandler: (state: any) => any) {
  const cachedDateString: string | undefined = mergedState.cachedDate;
  const cachedDate = cachedDateString ? new Date(cachedDateString) : undefined;
  const currentDateString = new Date().toISOString();
  const isTooOld =
    !cachedDate ||
    Date.now() - cachedDate.valueOf() >= MAX_FILTER_STORE_CACHE_AGE ||
    Date.now() - cachedDate.valueOf() <= 3000;
  return isTooOld
    ? { ...clearHandler(mergedState), cachedDate: currentDateString }
    : mergedState;
}

const filterStoreDB = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: 'rl-dashboard',
  version: 1.0,
  storeName: FILTER_STORAGE_KEY,
});

type FilterStoreKey = keyof StoreValue<typeof filterStore>;

const cachedFilterStateKeys: FilterStoreKey[] = [
  'cachedDate',
  'requestsCache',
  'requestsStatus',
  'selectionListEntities',
  'selectionListIds',
  'viewFilterDefaultEntities',
  'viewFilterDefaultIds',
];

export const persistFilterStore = persistState(filterStore, {
  key: FILTER_STORAGE_KEY,
  storage: filterStoreDB,
  preStoreInit: (mergedState) => {
    return preStoreInit(mergedState, (state) => {
      const nonCachedKeysState = pickBy(state, (v, key: FilterStoreKey) => {
        return !cachedFilterStateKeys.includes(key);
      });
      return {
        ...filterStore.initialState,
        ...nonCachedKeysState,
      };
    });
  },
  source: () =>
    filterStore.pipe(debounceTime(1000), pickKeys(cachedFilterStateKeys)),
});

export function doesFilterHaveState(id: SelectionCategories) {
  return filterStore.query(hasEntity(id));
}

export function upsertFilterStoreRootProps(
  propsMap: Partial<FilterStoreRootProps>
) {
  filterStore.update(setProps(propsMap));
}

export const selectionListDataSource = createRequestDataSource<
  SelectionList[],
  any,
  'selectionLists'
>({
  data$: (selectionListIds: SelectionListIdNames[]) => {
    // TODO: switch ref used based on passed ID's being apart of SelectionListIdNames or not
    let selector = selectManyByPredicate(
      (list) => selectionListIds.includes((list as SelectionList).id),
      { ref: selectionListEntitiesRef }
    );

    if (selectionListIds.includes(SelectionCategories.SAVED_FILTER_SET)) {
      selector = pipe(
        selectAllEntities({
          ref: storedFilterSetEntitiesRef as unknown as EntitiesRef<
            'selectionListEntities',
            'selectionListIds',
            'idKeySelectionList'
          >,
        }),
        map((allSets) => {
          const customSets = allSets.reduce((acc, curr) => {
            if (has(curr, 'creator')) {
              acc.push({
                ...curr,
                matchesActiveFilterSetView: curr?.view === getActiveSetId(),
                isDefault: curr?.isDefault,
              });
            }
            return acc;
          }, []);

          return [
            {
              id: SelectionCategories.SAVED_FILTER_SET,
              label: SelectionCategories.SAVED_FILTER_SET,
              value: customSets,
            },
          ];
        })
      );
      selectionListDataSource.setCached({
        key: SelectionCategories.SAVED_FILTER_SET,
      });
      selectionListDataSource.setSuccess({
        key: SelectionCategories.SAVED_FILTER_SET,
      });
    }

    return filterStore.pipe(selector, shareReplay({ refCount: true }));
  },
  dataKey: 'selectionLists',
  store: filterStore,
});

export const useSelectionListsData = (ids: SelectionListIdNames[]) => {
  const [selectionListsData, setSelectionListsData] = useState<
    SelectionList<ValidValueTypes>[]
  >([]);

  useEffect(() => {
    const subscription$ = selectionListDataSource
      .data$({ key: ids })
      .subscribe((d) => setSelectionListsData(d.selectionLists));

    return () => subscription$.unsubscribe();
  }, [ids]);

  return selectionListsData;
};

export function getActiveFiltersState(): Observable<Filter[]> {
  return filterStore.pipe(
    selectActiveEntities(),
    shareReplay({ refCount: true }) // maybe leave this, maybe not
  );
}

export const useActiveFiltersState = () => {
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);

  useEffect(() => {
    const subscription$ = filterStore
      .pipe(selectActiveEntities())
      .subscribe(setActiveFilters);

    return () => {
      subscription$.unsubscribe();
    };
  }, []);

  return activeFilters;
};

export const useAllFiltersState = () => {
  const [allFilters, setAllFilters] = useState<Filter[]>([]);

  useEffect(() => {
    const subscription$ = filterStore
      .pipe(selectAllEntities())
      .subscribe(setAllFilters);

    return () => {
      subscription$.unsubscribe();
    };
  }, []);

  return allFilters;
};

export function setActiveFiltersIds(filterIds: FilterNameIds[]) {
  filterStore.update(setActiveIds(filterIds));
}

export function addActiveFiltersIds(filterIds: FilterNameIds[]) {
  const activeIds = filterStore.query(getActiveIds);
  const uniqueActiveIds = uniq([...activeIds, ...filterIds]);
  filterStore.update(setActiveIds(uniqueActiveIds));
}

export function removeActiveFiltersIds(filterIds: FilterNameIds[]) {
  filterStore.update(removeActiveIds(filterIds));
}

export function getManyFiltersState(
  filterIds: FilterNameIds[] | Observable<FilterNameIds[]>,
  orderByFilterNames?: boolean
) {
  const filterIds$ = isObservable(filterIds) ? filterIds : of(filterIds);
  return combineLatest({
    filterIds: filterIds$,
    filters: filterStore.pipe(selectAllEntities()),
  }).pipe(
    map(({ filterIds = [], filters }) => {
      if (orderByFilterNames) {
        return compact(
          filterIds.map((fId) => filters.find((f) => fId == f.id))
        );
      } else {
        return compact(filters.filter((f) => filterIds.includes(f.id)));
      }
      // return filters.filter((f) => filterIds.includes(f.id));
    }),
    distinctUntilArrayItemChanged(),
    shareReplay<Filter[]>({ refCount: true })
  );
}

export const useSelectFilterById = (id: FilterName) => {
  const [filter, setFilter] = useState<Filter>();

  useEffect(() => {
    const subscription$ = filterStore.pipe(selectEntity(id)).subscribe((d) => {
      // TOOD: We need to figure out why snapshot_date entity is being removed from the store
      if (d !== undefined) setFilter(d);
    });
    return () => {
      subscription$.unsubscribe();
    };
  }, [id]);

  return filter;
};

export function getSingleFilterState<T = Filter>(
  filterId: FilterNameIds
): Observable<T> {
  // TODO: jbellizzi - fix to align with filterStore
  return filterStore.pipe(
    selectEntity(filterId),
    shareReplay({ refCount: true })
  ) as Observable<T>;
}

export function getSingleFilterStateSync<T = Filter | undefined, R = Filter>(
  filterId: FilterNameIds,
  map: (filter: T) => R = (f) => f as unknown as R
) {
  return map(filterStore.query(getEntity(filterId)) as T);
}

export function upsertFilter<T = Filter>(
  filterNameId: FilterNameIds,
  partialFilter: Partial<T>
) {
  filterStore.update(
    // TODO: jbellizzi - fix to align with filterStore
    upsertEntitiesById(filterNameId, {
      creator: (id) =>
        ({
          id: filterNameId,
          label:
            filterLabelLookup[filterNameId as SelectionCategories] ||
            filterNameId,
          type: FilterTypes.SELECT,
          value: undefined,
          ...partialFilter,
        }) as any,
      updater: write<T>(
        (filter) => ({ ...filter, ...partialFilter }),
        true
      ) as any,
    })
  );
}

// TODO: instead of creating filter value literals here with wrong labels, update this if its a selection type to lookup the value object in the selection list
export function upsertFiltersWithProvidedValue(
  staticFiltersMap: ProvidedFilterOrRawValueMap,
  setActive = false
) {
  const filterIds = keys(staticFiltersMap) as FilterNameIds[];
  filterStore.update(
    // TODO: jbellizzi - fix to align with filterStore
    upsertEntitiesById(filterIds, {
      creator: (id: FilterNameIds) => {
        let partialFilterOrValue = staticFiltersMap[id];
        if (
          isArray(partialFilterOrValue) &&
          !isObjectLike(partialFilterOrValue[0])
        ) {
          partialFilterOrValue = {
            isMulti: true,
            value: partialFilterOrValue.map((v) => ({
              id: v,
              value: v,
              label: `Value (${v})`,
            })),
          } as unknown as Partial<Filter>;
        }
        if (isString(partialFilterOrValue) || isFinite(partialFilterOrValue)) {
          partialFilterOrValue = {
            isMulti: false,
            value: {
              id: partialFilterOrValue,
              value: partialFilterOrValue,
              label: `Value (${partialFilterOrValue})`,
            },
          } as unknown as Partial<Filter>;
        }
        if (isBoolean(partialFilterOrValue)) {
          partialFilterOrValue = {
            type: FilterTypes.BOOLEAN,
            value: partialFilterOrValue,
          };
        }
        return {
          id,
          label: filterLabelLookup[id as SelectionCategories] || id,
          type: FilterTypes.SELECT,
          ...(partialFilterOrValue as Partial<Filter>),
        } as any;
      },
      updater: write<Filter>((filter) => {
        let partialFilterOrValue = staticFiltersMap[filter.id];
        if (
          isArray(partialFilterOrValue) &&
          !isObjectLike(partialFilterOrValue[0])
        ) {
          partialFilterOrValue = {
            value: partialFilterOrValue.map((v) => ({
              id: v,
              value: v,
              label: `Value (${v})`,
            })),
          } as unknown as Partial<Filter>;
        }
        if (isString(partialFilterOrValue) || isFinite(partialFilterOrValue)) {
          partialFilterOrValue = {
            isMulti: false,
            value: {
              id: partialFilterOrValue,
              value: partialFilterOrValue,
              label: `Value (${partialFilterOrValue})`,
            },
          } as unknown as Partial<Filter>;
        }

        if (
          [
            SelectionCategories.DATE_RANGE,
            SelectionCategories.DATE_RANGE_FULL,
          ].includes(filter.id as SelectionCategories)
        ) {
          const newIsMaximumRange = (partialFilterOrValue as RangeFilter)
            .isMaximumRange;
          partialFilterOrValue = {
            isMaximumRange:
              typeof newIsMaximumRange === 'boolean'
                ? newIsMaximumRange
                : (filter as RangeFilter).isMaximumRange,
            value: {
              ...(filter.value as DateRangeFormattedValues),
              ...(partialFilterOrValue as RangeFilter).value,
            },
          };
        }

        return { ...filter, ...(partialFilterOrValue as any) };
      }, true),
    })
  );

  if (setActive) {
    addActiveFiltersIds(filterIds);
  }
}

export function removeSelectFilterValue(
  filterNameId: FilterNameIds,
  filterId: FilterItem['id']
) {
  // TODO: jbellizzi - fix to align with filterStore
  filterStore.update(
    updateEntities(
      filterNameId,
      write<SelectFilter<FilterList<string | number>>>((filter) => {
        const indexToRemove = filter.value.findIndex((val) => {
          return typeof val === 'object'
            ? val.id === filterId
            : val === filterId;
        });

        if (indexToRemove !== -1) {
          filter.value.splice(indexToRemove, 1);
        }
      }) as any
    )
  );
}

export function removePrimaryEntityFilterValue(
  filterId: FilterItem['id'],
  selectionListId?: SelectionListIdNames
) {
  // TODO: jbellizzi - fix to align with filterStore
  filterStore.update(
    updateEntities(
      LocalSelectionCategories.PRIMARY_ENTITIES,
      write<SelectFilter<FilterList<string | number>>>((filter) => {
        const indexToRemove = filter.value.findIndex((val) => {
          const selectionListIdToMatch = get(val, 'selectionListId');
          const listIdMatch = selectionListIdToMatch === selectionListId;
          return val.id === filterId && listIdMatch;
        });

        if (indexToRemove !== -1) {
          filter.value.splice(indexToRemove, 1);
        }
      }) as any
    )
  );
}

export function deleteFilter(id: Filter['id']) {
  filterStore.update(deleteEntities(id));
}

export function deleteFilters(ids: Filter['id'][]) {
  filterStore.update(deleteEntities(ids));
}

export function addSelectionList(
  category: SelectionListIdNames,
  selectionItems: SelectionList['value']
) {
  filterStore.update(
    upsertEntities(
      {
        id: category,
        label: category,
        value: selectionItems,
      },
      { ref: selectionListEntitiesRef }
    ),
    selectionListDataSource.setCached({ key: category })
  );
}

export function usePrimaryFilter(filter: PrimaryFilters, setActive = true) {
  useEffect(() => {
    if (filter) {
      upsertFiltersWithProvidedValue(
        {
          [SelectionCategories.PRIMARY_FILTER]: filter,
        },
        setActive
      );
    }
  }, [filter, setActive]);
}

export function requireAtLeastOneFilterValueOf(
  filterNames: FilterName | FilterName[]
) {
  const filterNamesArray = isArray(filterNames) ? filterNames : [filterNames];
  // TODO: if we clear this filter(s) we need the plots to clear as well. So need to emit something for that to happen.
  return filter((source: PlotAdditionalQueryParams) => {
    const matchedFilter = (source.filters as SelectFilter<FilterList>[]).find(
      (f) => filterNamesArray.includes(f.id)
    );
    return matchedFilter ? !!matchedFilter.value?.length : false;
  });
}

export function createOneTimeEffectFn<T, R = unknown>(
  factoryFn: (source: Observable<T>) => Observable<R>
) {
  const subject = new Subject<T>();
  const obs = factoryFn(subject.asObservable());
  const sub = obs.subscribe();

  return function (value: T, unsubscribeAndRestart = false) {
    subject.next(value);
    if (unsubscribeAndRestart) {
      sub.unsubscribe();
      obs.subscribe();
    }
  };
}

function selectionListValueTransforms(
  selectionListNeeded: SelectionListIdNames,
  list: SelectionListResponse<ValidValueTypes>
): FilterList {
  // SKILLS page transformation hack to return in the same data shape as gql
  if (
    selectionListNeeded == (PYTHON_COMPANY_SELECTION_ID as SelectionCategories)
  ) {
    return (get(list, 'list', []) as Item[]).map((v: Item) => {
      const pId = `${v.data.parentId}`;
      return {
        ...v,
        ...v.data,
        parentId: pId,
        industry: pId,
        data: {
          ...v.data,
          parentId: pId,
          industry: pId,
        },
      };
    });
  }

  // has nested data object from legacy api to bring to top level
  if (get(list, 'list[0].data')) {
    return list.list.map((item) => ({
      ...item,
      ...(item as any).data,
    }));
  }

  // Provider check if user has website postings, then keep or remove it
  if (selectionListNeeded == LocalSelectionCategories.PROVIDER) {
    const userHasWebsitePostings = authStore.getValue().user?.linkup_postings;
    return get(list, 'list', []).filter((item) =>
      userHasWebsitePostings ? true : item.id != 2
    );
  }

  // otherwise just get the list
  return get(list, 'list', []);
}

/**
 * Fetching
 */
export function fetchFilterSelections(
  selectionListNeeded: SelectionListIdNames,
  {
    isLiveUser,
    isRevelioUser,
    restrictedCompanies,
    kibanaLogger,
  }: {
    isLiveUser?: Maybe<boolean> | boolean;
    isRevelioUser?: boolean;
    restrictedCompanies?: Maybe<string> | undefined;
    kibanaLogger?: KibanaLogger;
  } = { isLiveUser: true, isRevelioUser: false }
) {
  return of(selectionListNeeded).pipe(
    trackRequestResult([selectionListNeeded], {
      preventConcurrentRequest: true,
      cacheResponseData: true,
    }),
    selectionListDataSource.skipWhileCached({ key: selectionListNeeded }),
    switchMap((listName: SelectionListIdNames) => {
      // TODO: temp for Company Skills
      if (listName.startsWith('python_')) {
        listName = listName.slice(7) as SelectionListIdNames;
      }

      const useLimitedCompaniesList = !isLiveUser && restrictedCompanies;
      if (useLimitedCompaniesList) {
        listName = `${listName}/${restrictedCompanies}` as SelectionListIdNames;
      }
      if (
        isRevelioUser &&
        selectionListNeeded == LocalSelectionCategories.PROVIDER
      ) {
        listName = `rl-${listName}` as SelectionListIdNames;
      }
      const usingHardCodedList =
        Object.values(LocalSelectionCategories).includes(
          listName as LocalSelectionCategories
        ) || useLimitedCompaniesList;
      const endpoint = usingHardCodedList
        ? `/assets/filter-lists/${listName}.json`
        : `${API_ROOT}/api/filter/${listName}`;
      const STANDARD_HEADERS = {
        'Content-Type': 'application/json',
        'x-request-id': crypto.randomUUID(),
      };
      const headers = new Headers(
        usingHardCodedList
          ? { ...STANDARD_HEADERS, 'Cache-Control': 'max-age=600' }
          : STANDARD_HEADERS
      );

      return fromFetch<SelectionListResponse>(endpoint, {
        credentials: 'include',
        headers: headers,
        selector: (response) => {
          const { error } = handleAuthResponse(response);
          return error
            ? throwError(() => {
                const e = new Error(response.statusText) as Error & {
                  'request-id': string;
                };
                e['request-id'] = headers.get('request-id') || '';
                return e;
              })
            : response.json();
        },
      });
    }),
    filter((response: any) => response.cancel !== true),
    map<SelectionListResponse, SelectionList>((list) => ({
      id: selectionListNeeded,
      label: selectionListNeeded,
      value: selectionListValueTransforms(selectionListNeeded, list),
      parent: list?.parent,
    })),
    tap((list) => {
      addSelectionList(list.id, list.value);
    }),
    shareReplay({ refCount: true }),
    catchError((e: Error & { 'request-id': string }) => {
      kibanaLogger?.error(e.message, {
        context: 'GET SELECTION LIST',
        'request-id': e['request-id'],
        username: getAuthStoreUser()?.email as string | undefined,
      });
      return of([]);
    })
  );
}

function formatSubfilter(
  separateFilters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>
): EnumeratedFilters<ValidValueTypes | ValidValueTypes[]> {
  const mappedKeysFilters = mapKeys(separateFilters, (v, k) => {
    if (k.startsWith('sub_')) {
      return OtherFilterNames.SUBFILTER;
    }
    return k;
  });

  return mappedKeysFilters;
}

export function mergeFiltersTogether(
  formattedFilters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>,
  toFromPairs: [
    SelectionCategories | OtherFilterNames,
    SelectionCategories | OtherFilterNames,
  ][]
) {
  return produce(
    formattedFilters,
    (newFilters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>) => {
      toFromPairs.forEach(([to, from]) => {
        const pullFrom = get(newFilters, from, []);
        const pushTo = get(newFilters, to, []);
        if (!isArray(pullFrom) || !isArray(pushTo)) {
          throw new Error(
            'Can only merge Filters that accept multiple values.'
          );
        }
        if (pullFrom) {
          newFilters[to] = [...pushTo, ...pullFrom] as ValidValueTypes[];
          delete newFilters[from];
        }
        if (isEmpty(newFilters[to])) {
          delete newFilters[to];
        }
      });
    }
  );
}

const DataKeyLookup: { [key in keyof TalentDiscoveryV1_5Filter]?: string } = {
  [SelectionCategories.COMPANY]: 'data.rcid',
  free_texts: 'value',
  [SelectionCategories.RSID]: 'rsid',
};

const TDFilterNameOverride: Record<string, keyof TalentDiscoveryV1_5Filter> = {
  [SelectionCategories.METRO_AREA]: 'msa',
  // [SelectionCategories.SKILL_K3000]: 'skill',
  [SelectionCategories.BASE_SALARY]: 'salary_base',
  [SelectionCategories.TOTAL_COMPENSATION]: 'salary_total',
  [SelectionCategories.KEYWORD]: 'free_texts',
};

// TODO: need to type filters here
export function serializeTDFilters(
  filters: Filter[]
): TalentDiscoveryV1_5Filter {
  const serializedFilters: Partial<TalentDiscoveryV1_5Filter> = {};

  filters.forEach((fil) => {
    const {
      id: rawID,
      isCurrent = true,
      value,
    } = fil as Filter & { isCurrent?: boolean };
    if (
      [
        SelectionCategories.SKILL_K75,
        SelectionCategories.SKILL_K700,
        SelectionCategories.SKILL_K3000,
      ].includes(rawID as SelectionCategories)
    ) {
      return; // skipping skills as its handled in TD context seperately to filter global store
    }

    const hasCurrentOption = filtersWithCurrentOption.includes(rawID);

    const id: keyof TalentDiscoveryV1_5Filter =
      TDFilterNameOverride[rawID] || rawID;

    if (id === 'name') {
      serializedFilters['name'] = value as string;
      return;
    }

    if (id === 'industry') {
      serializedFilters['industry'] = {
        current: true,
        ids: (value as FilterList).map((v) =>
          isNumber(v.id) ? v.id : parseInt(v.id)
        ),
        non_current: false,
      };
      return;
    }

    if (['salary_base', 'salary_total'].includes(id)) {
      const {
        operator: { value: opValue },
        value: salaryValues,
      } = value as unknown as {
        operator: OperatorOptionType;
        value: [number, number];
      };

      let start_value;
      let end_value;

      if (opValue === OPValues.BETWEEN) {
        start_value = salaryValues[0];
        end_value = salaryValues[1];
      }

      if (opValue === OPValues.LESS) {
        start_value = 0;
        end_value = salaryValues[0];
      }

      if (opValue === OPValues.GREATER) {
        start_value = salaryValues[0];
        end_value = SALARY_MAX;
      }
      serializedFilters[id as 'salary_base' | 'salary_total'] = {
        start_value,
        end_value,
        ...(hasCurrentOption && {
          current: isCurrent,
          non_current: !isCurrent,
        }),
      } as TalentDiscoverySalaryRange;
      return;
    }

    const dataPath = DataKeyLookup[id] || 'id';

    let subsidiaryRCIDs: (number | string)[] = [];

    const mappedValues = (value as FilterValue[]).map((val: any) => {
      const collectedSubsidiaryRCIDs = get(val, 'subsidiaryRCIDs', []);

      subsidiaryRCIDs = [...subsidiaryRCIDs, ...collectedSubsidiaryRCIDs];

      return get(val, dataPath) ?? get(val, 'id');
    });

    if (id === 'free_texts') {
      // Guarantees keywords are a nested array to match BE schema.
      serializedFilters['free_texts'] = isNestedArray(mappedValues)
        ? mappedValues
        : [mappedValues];
    } else if (hasCurrentOption) {
      serializedFilters[id] = {
        ids: mappedValues
          .concat(subsidiaryRCIDs)
          .map((val: string) => Number(val)),
        current: isCurrent,
        non_current: !isCurrent,
      } as any;
    } else {
      serializedFilters[id] = mappedValues.map((val: string) =>
        Number(val)
      ) as any;
    }
  });

  return serializedFilters;
}

// TODO: need to type input and output of function here
const overrideHandlersMap: Record<string, (filters: any) => any> = {
  [Views.TALENT_DISCOVERY]: serializeTDFilters,
};

export function serializeFiltersForQuery(
  filters: Filter[],
  brokenOutFilterIds: FilterBase['id'][] = [],
  isGoRequest = false,
  view = Views.NONE
): SerializedFiltersForQuery {
  const overrideHandler = overrideHandlersMap[view];

  if (!isUndefined(overrideHandler)) {
    return overrideHandler(filters);
  }
  // TODO: maybe make these etl things into a proper pipeline, check out lodash flow, it might work for this
  const { formattedFilter, separateFilters } = formatAndBreakoutFilters(
    filters,
    [...brokenOutFilterIds, OtherFilterNames.ROLE_TAXONOMY]
  );

  const separateFiltersWithFormattedSubfilter =
    formatSubfilter(separateFilters);

  const hasCustomTaxonomyEnabled = filters.find(
    (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
  )?.value as FilterItem<CustomRoleTaxonomySelection>;
  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: hasCustomTaxonomyEnabled,
  });

  const result = {
    filters: mergeFiltersTogether(
      hasCustomTaxonomyEnabled
        ? formattedFiltersWithCustomRole
        : formattedFilter,
      [[SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY]]
    ),
  };

  let allOfIt = { ...result, ...separateFiltersWithFormattedSubfilter };
  // TODO: move this into serialize file and handling
  if (isGoRequest) {
    allOfIt.filters = mapKeys(allOfIt.filters, (v, key) => {
      const mappedKeyName = GqlFilterKeyMapper[key as SelectionCategories];
      return mappedKeyName || key;
    });
    allOfIt = mapKeys(allOfIt, (v, key) => {
      const mappedKeyName = GqlFilterKeyMapper[key as SelectionCategories];
      return mappedKeyName || key;
    }) as typeof allOfIt;
  }

  return omitBy(allOfIt, (val) => {
    return isBoolean(val) ? false : isObjectLike(val) && isEmpty(val);
  });
}

function internalDataFetch({
  url,
  search,
  body,
  abortController,
  method = RequestMethod.GET,
  isGoRequest,
  kibanaLogger,
}: IInternalDataFetch) {
  if (search && method == RequestMethod.GET) {
    url.search = search;
  }
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'x-request-id': crypto.randomUUID(),
  };

  const internalRequest = [API_ROOT, GO_API_ROOT].includes(url.origin);
  if (!internalRequest) {
    unset(defaultHeaders, 'Authorization');
  }

  return fromFetch<any>(url.toString(), {
    signal: abortController?.signal,
    method,
    credentials: internalRequest ? 'include' : 'omit',
    headers: defaultHeaders,
    body,
    selector: (response) => {
      console.info(`Plot request: ${url.pathname}`, {
        search: url.search,
        host: url.host,
        href: url.href,
        method,
        body,
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      const hasContentDisposition = response.headers.has('Content-Disposition');
      if (response.ok && inRange(response.status, 200, 300)) {
        if (response.status === 204) {
          return of([]);
        }

        return hasContentDisposition
          ? Promise.all([
              response.blob(),
              response.headers.get('Content-Disposition'),
            ])
          : response.json();
      }

      if (response.status == 429) {
        emitAuthEvent({
          id: AuthEventIds.RESP_429,
          message:
            'Seassion limit reached. Seassions cleared, please log in again.', // TODO: not currently referenced, but we should when this pattern is more built out
        });
      } else {
        const { error } = handleAuthResponse(response);
        kibanaLogger?.[error ? 'error' : 'warn'](response.statusText, {
          context: `${method}|${hasContentDisposition ? 'Download data' : 'Plot request'}`,
          'request-id': defaultHeaders['x-request-id'],
          username: getAuthStoreUser()?.email as string | undefined,
          url: url.toString(),
        });
      }

      return of({
        error: true,
        message: response.statusText,
        status: response.status,
      });
    },
  });
}

export function queryEndpoint({
  url,
  requestHash,
  requestMethod = RequestMethod.GET,
  search,
  body,
  includeInGlobalLoader = true,
  preFetchQuery = false,
  abortController,
  catchErrorBool = false,
  catchFn,
  isGoRequest,
  kibanaLogger,
}: IQueryEndpoint) {
  return of(requestHash).pipe(
    tap((requestHash) => {
      if (includeInGlobalLoader) {
        addLoadingStatus(requestHash);
      }
    }),
    switchMap((requestHash) => {
      const hasCacheEntry = d3DataCache.has(requestHash);
      if (!hasCacheEntry) {
        d3DataCache.set(
          requestHash,
          internalDataFetch({
            url,
            search,
            body,
            method: requestMethod,
            abortController,
            isGoRequest,
            kibanaLogger,
          }).pipe(shareReplay(1))
        );
      }

      return d3DataCache.get(requestHash) as Observable<any>;
    }),
    startWith({ loading: true }),
    switchMap((resp) => {
      // if (!resp.loading) {
      //   removeLoadingStatus('tabChange');
      // }
      if (resp.error && !catchErrorBool) {
        return throwError(() => {
          const e = new Error(resp.message) as any;
          e.error = resp;
          return e;
        });
      }
      if (!resp.loading) {
        resp.requestHash = requestHash;
      }
      return of(resp);
    }),
    filter((q) => !preFetchQuery), // TODO: this doesn't work right as queryEndpoint is called for each http call, prefetch one's happen last and just complete without emitting anything. How to take just the first non-prefetch http call one?
    maybeCatchError(catchErrorBool, catchFn)
  );
}

export const selectionListsToByPassFetch: SelectionListIdNames[] = [
  SelectionCategories.SAVED_FILTER_SET,
  SelectionCategories.DATE_RANGE,
  SelectionCategories.SNAPSHOT_DATE,
];

/**
 * Effects
 */

function internalGetFilterSelectionLists(
  selectionListsNeeded: Observable<SelectionListIdNames[]>
) {
  return combineLatest({
    init: persistFilterStore.initialized$,
    user: authStore.pipe(
      select((state) => state.user),
      filterNil(),
      first()
    ),
    filterNames: selectionListsNeeded,
  }).pipe(
    map(({ filterNames, user }) => {
      return {
        user,
        listNames: filterNames.filter(
          (n) =>
            (
              [
                ...Object.values(LocalSelectionCategories),
                ...Object.values(SelectionCategories),
                'python_company', // TODO: temp for Skills page
              ] as SelectionListIdNames[]
            ).includes(n) !== undefined &&
            !selectionListsToByPassFetch.includes(n)
        ),
      };
    }),
    shareReplay({ refCount: true }),
    exhaustMap(({ listNames, user }) => {
      const restrictedCompanies = getUserMetadataValue(
        MetadataKey.LimitedCompanies,
        user
      );

      return combineLatest(
        listNames.map((list) => {
          const isCompanyOrIndustryList = [
            SelectionCategories.COMPANY,
            SelectionCategories.INDUSTRY,
          ].includes(list as SelectionCategories);
          return fetchFilterSelections(
            list,
            isCompanyOrIndustryList
              ? { isLiveUser: user.live, restrictedCompanies }
              : undefined
          );
        })
      );
    })
  );
}

export const getFilterSelectionLists = createEffectFn<SelectionListIdNames[]>(
  internalGetFilterSelectionLists
);

export const clearRoleCacheFilters = () => {
  console.log('Custom Role Taxonomy: Clearing Role Cache Filters');
  filterStore.update(
    // requests cache uses the keys flatly (from selection list or view filters) but library types not working
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateRequestsCache(ALL_ROLE_FILTERS as any, { value: 'none' })
  );
};
