import {
  Filter,
  FilterName,
  OtherFilterNames,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import { useMemo } from 'react';

export const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
}: {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
}) => {
  const activeFiltersHasPrimaryFilter = activeFilters.some((filter) =>
    primaryFilters.includes(filter.id)
  );

  const activeFiltersHasDateRange = activeFilters.some(
    (filter) => filter.id === SelectionCategories.DATE_RANGE_FULL
  );

  const filtersHasCustomRoleTaxonomy = useMemo(() => {
    return !!activeFilters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
  }, [activeFilters]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  return (
    activeFiltersHasPrimaryFilter &&
    activeFiltersHasDateRange &&
    (!isCustomRoleTaxonomyEnabled || filtersHasCustomRoleTaxonomy)
  );
};
