import { Meter } from '@revelio/d3';
import {
  CompositionDataQuery,
  GeoSummaryPostingsDataQuery,
} from '@revelio/data-access';
import { getValueRatio } from '../utils/getValueRatio';
import { getHumanReadableNumbers } from '../utils/getHumanReadableNumbers';
import { useState, useEffect } from 'react';
import { calculateMeterValue } from './meter-scaling/calculateMeterValue';

interface DemandSupplyMeterProps {
  compositionData: CompositionDataQuery | undefined;
  postingsData: GeoSummaryPostingsDataQuery | undefined;
}
export const DemandSupplyMeter = ({
  compositionData,
  postingsData,
}: DemandSupplyMeterProps) => {
  const [meterValue, setMeterValue] = useState<number | null>(null);

  useEffect(() => {
    const regionHeadcountTimeSeries =
      compositionData?.composition?.[0]?.metrics?.headcount?.timeseries;
    const headcount =
      regionHeadcountTimeSeries?.[regionHeadcountTimeSeries?.length - 1]
        ?.value || 0;

    const postingsCategory = postingsData?.posting?.[0]?.category;
    const postingsActive =
      postingsCategory?.[postingsCategory?.length - 1]?.metrics?.active || 0;

    const meterValue = calculateMeterValue({
      value: postingsActive / headcount,
      metric: 'demandSupply',
    });

    setMeterValue(meterValue);
  }, [compositionData, postingsData]);

  const regionHeadcountTimeSeries =
    compositionData?.composition?.[0]?.metrics?.headcount?.timeseries;
  const headcount =
    regionHeadcountTimeSeries?.[regionHeadcountTimeSeries?.length - 1]?.value ||
    0;

  const postingsCategory = postingsData?.posting?.[0]?.category;
  const postingsActive =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.active || 0;

  const demandsupplyRatio = getValueRatio(headcount, postingsActive);

  return (
    <Meter
      name="demand-supply-meter"
      data={{
        title: `${getHumanReadableNumbers(postingsActive)} postings vs ${getHumanReadableNumbers(headcount)} profiles`,
        value: meterValue,
      }}
      format="d"
      customFormatter={(_value: string) =>
        `${demandsupplyRatio?.[1].toLocaleString()}:${demandsupplyRatio?.[0].toLocaleString()}`
      }
      customMargins={{ top: 15, right: 0, bottom: 0, left: 0 }}
    />
  );
};
